import ButtonClickHandler from "./Analytics/Handlers/ButtonClickHandler";
import ClickLinkHandler from "./Analytics/Handlers/ClickLinkHandler";
import ClickLoadMoreButtonHandler from "./Analytics/Handlers/ClickLoadMoreButtonHandler";
import ClickRegisterNewsletter from "./Analytics/Handlers/ClickRegisterNewsletter";
import ClickTracker from "./DocumentTracker/ClickTracker";
import Cookies from "js-cookie";
import DeviceInstanceIdManager from "./User/DeviceInstanceIdManager";
import HandlersManager from "./Analytics/HandlersManager";
import LayoutCommon from "./Layout/LayoutCommon";
import ModuleManager from "./ModuleLoader/ModuleManager";
import MostRecent from "./MostRecent/MostRecent";
import MostRecentLoadMoreRepository from "./MostRecent/MostRecentLoadMoreRepository";
import NavigationLayout from "./Layout/NavigationLayout";
import NewsletterHandler from "./Analytics/Handlers/NewsletterHandler";
import NominationSlider from "./Slider/NominationSlider";
import ScrolledToBeginOfBlockHandler from "./Analytics/Handlers/ScrolledToBeginOfBlockHandler";
import ScrolledToBlockItemHandler from "./Analytics/Handlers/ScrolledToBlockItemHandler";
import TimeSpentHandler from "./Analytics/Handlers/TimeSpentHandler";
import User from "./User/User";
import Weather from "./Weather";
import WebPart from "./Layout/WebPart";
import { detect } from "detect-browser";

export class App {

    _globals = {};

    /**
     * @private
     * @var {User}
     */
    _user;

    /**
     * @private
     * @type {Window}
     */
    _domWindow;

    /**
     * @private
     * @type {Document}
     */
    _domDocument;

    /**
     * @type {DeviceInstanceIdManager}
     * @private
     */

    _deviceInstanceId = null;
    /**
     * @public
     * @returns {DeviceInstanceIdManager}
     */
    get deviceInstanceId() {
        if (this._deviceInstanceId == null) {
            this._deviceInstanceId = new DeviceInstanceIdManager();
        }

        return this._deviceInstanceId;
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * @public
     * @param obj {obj}
     */
    set globals(obj) {
        this._globals = obj;
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * @public
     */
    get globals() {
        return Object.assign(this._globals, {
            userId: this._user.anonymousUUID
        });
    }

    get user() {
        return this._user;
    }

    /**
     * @param {Window} window
     */
    constructor(window) {
        this._user = new User(this.deviceInstanceId);
        this._domWindow = window;
        this._domDocument = window.document;
        this._domDocument.addEventListener("DOMContentLoaded", this._boot.bind(this));

        if (this._domWindow.ngAppGlobals) {
            this._globals = this._domWindow.ngAppGlobals;
        }

        this._domWindow.googletag = this._domWindow.googletag || { cmd: [] };
    }

    _boot() {

        const browser = detect();
        if (browser) {
            this._domDocument.body.classList.add(browser.name);
        }

        const nominationListNode = this._domDocument.querySelector(".nominationList");
        if (nominationListNode) {
            const nominationSlider = new NominationSlider(nominationListNode);
            nominationSlider.disableResizeListeners();
            nominationSlider.boot();
        }

        this._bootAdmin();
        new LayoutCommon(this._domWindow).boot();
        new NavigationLayout(this._domDocument, this._domWindow).boot();
        new Weather(window.WEATHER_CITIES_ENDPOINT_URL,
            window.WEATHER_DEFAULT_CITY_ID,
            window.WEATHER_PATH,
            window.STATIC_LPCDN_BASE_URL);

        (new ModuleManager(this._domWindow, this._analyticsManager)).load();

        this._analyticsManager = new HandlersManager(this._domWindow, this._user);
        this._analyticsManager.addHandlers([
            new ClickRegisterNewsletter("input.nl_box-wrapper-form-submit"),
            new ClickLoadMoreButtonHandler(".mostRecentList__loadMoreBtn"),
            new ButtonClickHandler(".simpleButton"),
            new ButtonClickHandler(".newsletterPost__preview-button"),
            new TimeSpentHandler("desktop"),

            new NewsletterHandler(),

            new ScrolledToBlockItemHandler("[data-location=mostRecents]", "mostRecents", "article", (node) => node.querySelector("a[itemprop=\"url\"]")),
            new ScrolledToBeginOfBlockHandler("[data-location=trending]", "trending"),
            new ScrolledToBeginOfBlockHandler("[data-location=videosList]", "videosList"),
            new ScrolledToBeginOfBlockHandler("[data-location=footer]", "footer"),

            new ClickLinkHandler(),
        ]);

        this._analyticsManager.startTracking();
        new ClickTracker(this._domWindow, this._user);
        this._bootMostRecentLoadMoreButton();
    }

    _bootMostRecentLoadMoreButton() {
        const repository = new MostRecentLoadMoreRepository(this._domDocument);
        const mostRecent = new MostRecent(this._domDocument, repository);
        mostRecent.trackLoadMore();
    }

    _bootAdmin() {
        const adminJsAndCssWebpartElement = this._domDocument.querySelector('.webpart[data-webpart-type="admin"]');

        if (adminJsAndCssWebpartElement === null) {
            return false;
        }

        new WebPart(
            this._domDocument,
            adminJsAndCssWebpartElement,
        ).load();
    }

    static _shouldDisplayAdminJsAndCss() {
        return Cookies.get("CrayonsViewSwitch") === "active";
    }
}

export const ngApp = new App(window);
