import ClipboardJS from "clipboard";

export default class LayoutCommon {

    /**
     * @var {Window}
     * @private
     */
    _window;

    constructor(window) {
        this._window = window;
    }

    boot() {
        this._bootToggleClasses();
        this._bootSocialLinkCopy();
    }

    /**
     *
     * @private
     */
    _bootToggleClasses() {

        document.addEventListener("click", (e) => {

            const target = e.target.closest("[data-toggle-class]");

            if (!target) {
                return;
            }

            const toggleClass = target.dataset.toggleClass;
            const toggleSelector = target.dataset.toggleClassTarget;
            const toggleElement = toggleSelector ? document.querySelector(toggleSelector) : target;

            toggleElement.classList.toggle(toggleClass);
        });
    }

    /**
     *
     * @private
     */
    _bootSocialLinkCopy() {

        new ClipboardJS(".btn-copy");

        document.addEventListener("click", (e) => {

            const target = e.target.closest(".btn-copy");

            if (!target) {
                return;
            }

            e.preventDefault();

            if (ClipboardJS.isSupported()) {
                this._showCopyLinkConfirmation(target.closest(".copyLink").querySelector(".placeholderCopy"));
            } else {
                this._window.prompt("Copier le lien. (\"Ctrl/Cmd + C\").", this._window.location.href);
            }
        });
    }

    /**
     *
     * @private
     */
    _showCopyLinkConfirmation(target) {
        if ("undefined" === typeof target.animate) {
            return;
        }

        target.style.display = "inline";
        target
            .animate({opacity: [0, 1, 1, 0]}, {duration: 1400, iterations: 1, easing: "ease-out"})
            .onfinish = () => {
                target.style.display = "none";
            };
    }
}
