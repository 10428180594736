import debounce from "lodash.debounce";

export default class NominationSlider {

    /**
     * @var slideIndex
     * @private
     */
    _slideIndex = 1;

    /**
     * @var slidesOffsetWidth
     * @private
     */
    _slidesOffsetWidth;

    /**
     * @var {boolean} _ShouldListenToResize
     * @private
     */
    _shouldListenToResize;

    /**
     * @var {Window}
     * @private
     */
    _window;

    /**
     * @var {Window}
     * @private
     */
    _rootNode;

    constructor(rootNode) {
        this._rootNode = rootNode;

        this._window = this._rootNode.ownerDocument.defaultView || this._rootNode.ownerDocument.parentWindow;
    }

    enableResizeListeners() {
        this._shouldListenToResize = true;
    }

    disableResizeListeners() {
        this._shouldListenToResize = false;
    }

    boot() {
        this._initEventListeners();
        this._initSlider();
    }

    _initSlider() {
        this._getSlidesOffsetWidth();
        this._showSlides(this._slideIndex);
    }

    _initEventListeners() {
        this._rootNode.querySelector("#slideshow__btn--prev").addEventListener("click", () => {
            this._decrementSlides();
        });
        this._rootNode.querySelector("#slideshow__btn--next").addEventListener("click", () => {
            this._incrementSlides();
        });
        if (this._shouldListenToResize) {
            this._window.addEventListener("resize", debounce(() =>  {
                this._initSlider();
            } ,400));
        }
    }

    _getSlidesOffsetWidth() {
        let slide= this._rootNode.querySelectorAll(".slide");
        this._slidesOffsetWidth = slide[0].clientWidth;
    }

    _incrementSlides() {
        this._slideIndex++;
        this._showSlides();
    }

    _decrementSlides() {
        this._slideIndex--;
        this._showSlides();
    }

    _showSlides() {
        let slideshow = this._rootNode.querySelector("#slideshow__container");
        let slides = Array.prototype.slice.call(this._rootNode.querySelectorAll(".slide"));
        let next = this._rootNode.querySelector("#slideshow__btn--next");
        let prev = this._rootNode.querySelector("#slideshow__btn--prev");

        next.classList.remove("disabled");
        prev.classList.remove("disabled");

        if (this._slideIndex === 1) {
            prev.classList.add("disabled");
        }
        if (this._slideIndex >= slides.length) {
            next.classList.add("disabled");
        }
        if (slides[this._slideIndex - 1]) {
            let leftPosition = (this._slideIndex - 1) * (this._slidesOffsetWidth + 4);
            slideshow.style.transform = "translateX(-" + leftPosition + "px)";
        }
    }
}
